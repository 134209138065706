import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const SheldonPage = ({ data }) => {

  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`Sheldon Chalet Branding & Website - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>Sheldon Chalet Branding & Website</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="The Sheldon Chalet is a luxury destination that offers visitors an incomparable experience of Denali in a multi-million
        dollar chalet, perched on a nunatak in the middle of the Ruth Glacier. The Sheldon family approached Alopex having seen their
        work with K2 Aviation and needing direction in positioning the Sheldon Chalet in the competitive environment of luxury travel.
        Though highly engineered, this project was a blank slate in terms of marketing."
        img={data.hiking.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="#415362"
        overlayOpacity="0.8"
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.logo.childImageSharp.fluid} alt="Sheldon Chalet Logo" />
      {/** BLOCK 03 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#eee"
        heading="Brand"
        body="As a family project with deep history, the Sheldon Chalet warranted a deep discovery process for the brand that
        would do justice to the environment. Alopex investigated a number of branding traditions, including national parks, luxury
        hotels, and adventure apparel. As with all Alopex branding projects, sketches became compositions that were presented to
        and refined with the client. The resulting mark is stark and elegant with the chalet, Mount Dan Beard, and the dome of the
        sky behind it. The dramatic font of “Sheldon Chalet” pairs beautifully with the modernism of “Experience Grand”, the
        tagline discovered by the Alopex creative team."
      />
      {/** BLOCK 04 */}
      <PlainImage img={data.responsive.childImageSharp.fluid} alt="Responsive Design" />
      {/** BLOCK 05 */}
      <Columns
        blockLeft={
          <PlainText
            color={colors.brand.slate}
            bgColor="#eee"
            size="small"
            heading="Photography"
            body="<p>Undoubtedly, the star of SheldonChalet.com is the photography. Alopex had the fortune of working with the legendary
            Jeff Schultz to produce the assets for Sheldon Chalet.</p>
            <p>Art direction is an important aspect of content creation, helping focus the style and tone of visuals.</p>
            <p>Visit <a href='https://schultzphoto.com' target='_blank' rel='noopener noreferrer'>schultzphoto.com</a> to see more of Jeff’s
            iconic work.</p>"
          />
        }
        blockRight={
          <PlainImage img={data.accordion.childImageSharp.fluid} size="small" alt="Internal page with photography and accordions" />
        }
      />
      {/** Block 06 */}
      <Columns
        blockLeft={
          <PlainImage img={data.noplace.childImageSharp.fluid} size="small" alt="Bespoke Elements" />
        }
        blockRight={
          <PlainText
            color={colors.brand.lightGray}
            size="small"
            bgColor="#47687b"
            heading="Bespoke Elements"
            body="<a href='https://sheldonchalet.com/' target='_blank' rel='noopener noreferrer'>SheldonChalet.com</a> is peppered with
            highly designed interactive elements. From micro-animations to layered blueprints, this website is a prime example of how
            design and coding can fuse together to create something magical."
          />
        }
      />
      {/** BLOCK 07 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#eee"
        heading="Website"
        body="<a href='https://sheldonchalet.com' target='_blank' rel='noopener noreferrer'>SheldonChalet.com</a> is the most refined and
        elegant websites that Alopex has created. This is heavily due to the fact that the Alopex team was able to work with a blank
        slate—everything from the copy to the imagery to the illustrations was purposefully directed and produced by the Alopex team.
        Featuring photography from the legendary Jeff Schultz, the website provides the user with a taste of the Sheldon Chalet experience.
        Highly editorial styles create a unique digital experience that piques the interest of the viewer."
      />
      {/** BLOCK 08 */}
      <PlainImage img={data.mermaid.childImageSharp.fluid} alt="More internal and footer" />
      {/** BLOCK 09 */}
      <PlainImage img={data.hero.childImageSharp.fluid} alt="There goes my hero" />
      {/** BLOCK 10 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#576978"
        heading="Results"
        className="lastBlock"
        body="Sheldon Chalet is enjoying the attention of national media—while being prepared for it. The brand of the Sheldon Chalet
        marries perfectly with their marketing and social media presence, presenting a unified vision and flavor that makes branding
        easy. The Sheldon Chalet opened to guests in February 2018 and has been featured in many publications and shows."
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query sheldonPage {
    site {
      siteMetadata {
        title
      }
    }
    hiking: file(relativePath: { eq: "casestudies/sheldon/171109-5D8143.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: file(relativePath: { eq: "casestudies/sheldon/Alopex_SC05.1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    responsive: file(relativePath: { eq: "casestudies/sheldon/Alopex_SC01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accordion: file(relativePath: { eq: "casestudies/sheldon/Alopex_SC04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    noplace: file(relativePath: { eq: "casestudies/sheldon/Alopex_SC02.1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mermaid: file(relativePath: { eq: "casestudies/sheldon/Alopex_SC03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero: file(relativePath: { eq: "casestudies/sheldon/Sheldon-Chalet-Hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default SheldonPage
